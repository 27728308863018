<template>
  <div class="flex flex-wrap justify-center">
    <div class="bg-white p-6 sm:w-full md:w-3/5 lg:w-1/2 xl:w-1/2">
      <h1 class="text-center my-6">{{ $t("YourSubscription") }}</h1>
      <h5 class="text-center text-grey font-medium m-8">
        {{ $t("SubscriptionPro") }}
      </h5>
      <div class="flex flex-wrap mb-3">
        <input
          name="email"
          data-vv-validate-on="blur"
          v-validate="'required'"
          class="w-full vs-inputx vs-input--input normal input-onboarding"
          v-model="email"
        />
        <div class="label-onboarding">Email Financeiro</div>
        <span class="text-danger text-sm">{{ errors.first("email") }}</span>
      </div>
      <div class="flex flex-wrap mb-3">
        <the-mask
          id="cpfCnpj"
          name="cpf"
          data-vv-validate-on="blur"
          v-validate="'required'"
          class="w-full vs-inputx vs-input--input normal input-onboarding"
          v-model="cnpjCpf"
          :mask="['###.###.###-##', '##.###.###/####-##']"
        />
        <div class="label-onboarding">CNPJ/CPF</div>
        <span class="text-danger text-sm">{{ errors.first("cpf") }}</span>
      </div>
      <div class="flex flex-wrap mb-3">
        <v-select
          id="payment"
          v-model="payment"
          :clearable="false"
          :options="paymentOptions"
          name="payment"
          class="w-full"
        />
        <div class="label-onboarding">Forma de pagamento</div>
        <span class="text-danger text-sm">{{ errors.first("payment") }}</span>
      </div>
      <template v-if="payment.value == 'card'">
        <div class="flex flex-wrap mb-3">
          <input
            type="text"
            class="w-full vs-inputx vs-input--input normal input-onboarding"
            v-model="cardName"
            name="cardName"
            data-vv-validate-on="blur"
            v-validate="'required'"
          />
          <div class="label-onboarding">Nome do titular</div>
          <span class="text-danger text-sm">{{
            errors.first("cardName")
          }}</span>
        </div>
        <div class="flex flex-wrap mb-3">
          <the-mask
            data-vv-validate-on="blur"
            v-validate="'required'"
            class="w-full vs-inputx vs-input--input normal input-onboarding"
            v-model="cardNumber"
            name="cardNumber"
            :mask="['#### ##### #### ####']"
          />
          <span v-if="cardError" class="text-danger text-sm"
            >Cartão inválido, verifique os números.</span
          >
          <div class="label-onboarding">Número do cartão</div>
          <span class="text-danger text-sm">{{
            errors.first("cardNumber")
          }}</span>
        </div>
        <label class="vs-input--label w-full"
          >Informe a validade do cartão</label
        >
        <div class="flex flex-wrap content-between mb-3">
          <div class="w-1/2 flex flex-wrap">
            <the-mask
              data-vv-validate-on="blur"
              v-validate="'required'"
              class="placeholder-grey w-11/12 vs-inputx vs-input--input normal input-onboarding"
              v-model="cardValidate.month"
              name="cardMonth"
              :mask="['##']"
              placeholder="ex: 03"
            />
            <div class="label-onboarding">Mês</div>
            <span class="text-danger text-sm">{{
              errors.first("cardMonth")
            }}</span>
          </div>
          <div class="w-1/2 flex flex-wrap">
            <the-mask
              data-vv-validate-on="blur"
              v-validate="'required'"
              class="placeholder-grey w-11/12 vs-inputx vs-input--input normal input-onboarding"
              v-model="cardValidate.year"
              name="cardYear"
              :mask="['####']"
              placeholder="ex: 2020"
            />
            <div class="label-onboarding">Ano</div>
            <span class="text-danger text-sm">{{
              errors.first("cardYear")
            }}</span>
          </div>
        </div>
        <div class="w-1/2 flex flex-wrap mb-3">
          <the-mask
            data-vv-validate-on="blur"
            v-validate="'required'"
            class="placeholder-grey vs-inputx vs-input--input normal input-onboarding"
            v-model="cardValidate.number"
            name="cardValidateNumber"
            :mask="['###']"
            placeholder="ex: 123"
          />
          <div class="label-onboarding">Código de segurança</div>
          <span class="text-danger text-sm">{{
            errors.first("cardValidateNumber")
          }}</span>
        </div>
      </template>
      <div class="flex flex-wrap mb-3">
        <the-mask
          data-vv-validate-on="blur"
          v-validate="'required'"
          class="w-full vs-inputx vs-input--input normal input-onboarding"
          v-model="cep"
          name="cep"
          :mask="['#####-###']"
        />
        <div class="label-onboarding">CEP</div>
        <span v-if="errorCep" class="text-danger text-sm">{{ errorCep }}</span>
        <span class="text-danger text-sm">{{ errors.first("cep") }}</span>
      </div>
      <div v-if="showAdress">
        <div class="flex flex-wrap mb-3">
          <input
            type="text"
            class="w-full vs-inputx vs-input--input normal input-onboarding"
            v-model="address"
            name="address"
            data-vv-validate-on="blur"
            v-validate="'required'"
          />
          <div class="label-onboarding">Endereço</div>
          <span class="text-danger text-sm">{{ errors.first("address") }}</span>
        </div>
        <div class="flex flex-wrap mb-3">
          <input
            type="text"
            class="w-full vs-inputx vs-input--input normal input-onboarding"
            v-model="numberAddress"
            name="numberAddress"
            data-vv-validate-on="blur"
            v-validate="'required'"
          />
          <div class="label-onboarding">Número</div>
          <span class="text-danger text-sm">{{
            errors.first("numberAddress")
          }}</span>
        </div>
        <div class="flex flex-wrap mb-3">
          <input
            type="text"
            class="w-full vs-inputx vs-input--input normal input-onboarding"
            v-model="neighborhood"
            name="neighborhood"
            data-vv-validate-on="blur"
            v-validate="'required'"
          />
          <div class="label-onboarding">Bairro</div>
          <span class="text-danger text-sm">{{
            errors.first("neighborhood")
          }}</span>
        </div>
        <div class="flex flex-wrap mb-3">
          <input
            type="text"
            class="w-full vs-inputx vs-input--input normal input-onboarding"
            v-model="city"
            name="city"
            data-vv-validate-on="blur"
            v-validate="'required'"
          />
          <div class="label-onboarding">Cidade</div>
          <span class="text-danger text-sm">{{ errors.first("city") }}</span>
        </div>
        <div class="flex flex-wrap mb-3">
          <input
            type="text"
            class="w-full vs-inputx vs-input--input normal input-onboarding"
            v-model="stateAdress"
            name="stateAddress"
            data-vv-validate-on="blur"
            v-validate="'required'"
          />
          <div class="label-onboarding">Estado</div>
          <span class="text-danger text-sm">{{
            errors.first("stateAddress")
          }}</span>
        </div>
      </div>
      <div class="flex flex-wrap mb-3">
        <input
          data-vv-validate-on="blur"
          class="w-full vs-inputx vs-input--input normal input-onboarding"
          v-model="coupon"
          @blur="checkCoupon()"
          name="coupon"
        />
        <div class="label-onboarding">Cupom de Desconto</div>
        <span v-if="errorCoupon" class="text-danger text-sm">{{
          errorCoupon
        }}</span>
        <span class="text-danger text-sm">{{ errors.first("coupon") }}</span>
      </div>
      <div class="flex flex-wrap justify-end mt-6">
        <vs-divider position="left" color="primary">
          <div class="text-lg font-bold">Resumo do plano</div>
        </vs-divider>
        <div class="w-full pl-4">
          <p>
            Plano <span class="font-bold">{{ planData.title }}</span>
          </p>
          <p>
            Valor <span class="font-bold">R$ {{ planData.price }}</span> / mês
          </p>
          <p v-if="couponDiscountValue">
            Desconto <span class="font-bold">{{ couponDiscountValue }}</span>
          </p>
          <p v-if="couponDiscountValue">
            Valor Final
            <span class="font-bold">R$ {{ couponDiscountPrice }}</span> / mês
          </p>
        </div>
      </div>
      <vs-button
        ref="loadableButton"
        id="button-with-loading"
        class="w-full mt-8"
        color="success"
        @click="submitForm()"
        :disabled="!validateForm"
      >
        <div v-if="loading" class="spinner" id="spinner"></div>
        <div v-else>
          <span>{{ $t("SubscribePlan") }}</span>
        </div>
      </vs-button>
      <div class="flex mt-6 justify-center" style="opacity: 0.8">
        <small>
          {{ $t("AgreeWithPolicy") }}
          <a href="https://www.duotalk.io/termos-de-uso/" target="_blank">{{
            $t("TermsOfService")
          }}</a>
          {{ $t("And") }}
          <a
            href="https://www.duotalk.io/politica-de-privacidade/"
            target="_blank"
            >{{ $t("PrivacyPolicy") }}</a
          >.
        </small>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
(function () {})();
</script>
<script>
import vSelect from "vue-select";
import axios from "axios";
import { TheMask } from "vue-the-mask";

export default {
  components: {
    "v-select": vSelect,
    TheMask,
  },
  mounted() {
    this.getFinancialDetails();
  },
  data() {
    return {
      superlogicaId: "",
      cardError: false,
      showAdress: false,
      errorCep: false,
      company: "",
      trial: 0,
      loading: false,
      cnpjCpf: "",
      coupon: "",
      email: this.$store.state.user.email,
      payment: "",
      paymentOptions: [
        {
          label: "Cartão de crédito",
          value: "card",
        },
        {
          label: "Boleto",
          value: "bankSlip",
        },
      ],
      cardNumber: "",
      cardValidate: {
        month: "",
        year: "",
        number: "",
      },
      address: "",
      numberAddress: "",
      neighborhood: "",
      city: "",
      stateAdress: "",
      cep: "",
      cardFlag: "",
      cardName: "",
      terms: 1,
      currentPlan: {},
      couponChecked: true,
      errorCoupon: false,
      couponDiscountValue: false,
      couponDiscountPrice: false,
    };
  },
  computed: {
    accName() {
      return this.$store.state.acc.current_acc.name;
    },
    planData() {
      return this.currentPlan;
    },
    acc() {
      return this.$store.state.acc.current_acc.id;
    },
    plan() {
      return this.$route.params.plan;
    },
    accWhitelabel() {
      return this.currentPlan.whitelabel;
    },
    validateForm() {
      return (
        !this.errors.any() &&
        this.cnpjCpf.length > 0 &&
        this.payment.value &&
        this.errorCep == false &&
        this.cep.length == 8 &&
        this.numberAddress.length > 0 &&
        (this.payment.value !== "card" ||
          (this.payment.value == "card" &&
            this.cardNumber.length > 0 &&
            this.cardValidate.month.length &&
            this.cardValidate.year.length > 0 &&
            this.cardValidate.number.length > 0 &&
            this.cardName.length > 0))
      );
    },
  },
  watch: {
    cep(value) {
      if (value.length == 8) {
        this.errorCep = false;
        this.searchCep();
      }
    },
    coupon(val) {
      this.couponDiscountValue = false;
      this.couponChecked = val.length === 0;
      this.errorCoupon = false;
    },
  },
  methods: {
    checkCoupon() {
      if (this.coupon.length === 0) return;
      this.loading = true;
      this.errorCoupon = false;
      this.$http
        .get(
          `/g/acc/plan/financial/checkout/validate-coupon?coupon=${this.coupon}&idPlanSuperlogica=${this.currentPlan.plan.idSuperlogica}`
        )
        .then((response) => {
          this.loading = false;
          if (response && response.data && response.data.data) {
            const responseData = response.data.data;
            if (responseData.valid) {
              if (
                responseData.id_plano_pla.length > 0 &&
                String(responseData.id_plano_pla) !==
                  String(this.currentPlan.plan.idSuperlogica)
              )
                this.errorCoupon = this.$t("InvalidCoupon");
              else {
                this.couponDiscountValue = responseData.pricePercentageDiscount
                  ? responseData.pricePercentageDiscount + "%"
                  : `R$ ${responseData.priceValueDiscount}`;
                this.couponDiscountPrice = responseData.pricePercentageDiscount
                  ? this.currentPlan.price -
                    this.currentPlan.price *
                      (responseData.pricePercentageDiscount / 100)
                  : this.currentPlan.price - responseData.priceValueDiscount;
                this.couponChecked = true;
              }
            } else this.errorCoupon = this.$t("InvalidCoupon");
          } else this.errorCoupon = this.$t("InvalidCoupon");
        })
        .catch((err) => {
          this.loading = false;
          if (
            err.response &&
            err.response.data &&
            err.response.data.message === 404
          )
            this.errorCoupon = this.$t("InvalidCoupon");
          this.$vs.notify({
            title: this.$t("Error"),
            text: "Erro",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        });
    },
    getPlanDetails() {
      this.$http
        .get(`/g/product/plan/${this.plan}`)
        .then((response) => {
          if (response && response.data && response.data.data)
            this.currentPlan = response.data.data;
        })
        .catch((err) => {
          this.$vs.notify({
            title: this.$t("Error"),
            text: "Erro",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        });
    },
    getFinancialDetails() {
      this.$http({
        method: "GET",
        url: `/g/acc/plan/financial/${this.acc}`,
      }).then((response) => {
        this.superlogicaId = response.data.data.superlogicaIdCliente;
        this.superlogicaIdCliente = response.data.data.superlogicaIdCliente;
        this.superlogicaPlanId = response.data.data.superlogicaPlanId;
      });
    },
    submitForm() {
      if (!this.getCardFlag(this.cardNumber)) this.cardError = true;
      this.loading = true;
      let payload = {
        acc: this.$store.state.acc.current_acc.id,
        trial: 0,
        idplano: this.planData.plan.idSuperlogica,
        ST_NOME_SAC: this.accName,
        ST_EMAIL_SAC: this.email,
        senha: this.cnpjCpf,
        confirmacao_senha: this.cnpjCpf,
        aceite_contrato: 1,
        FL_MESMOEND_SAC: 1,
        ST_ENDERECO_SAC: this.address,
        ST_NUMERO_SAC: this.numberAddress,
        ST_BAIRRO_SAC: this.neighborhood,
        ST_CIDADE_SAC: this.city,
        ST_ESTADO_SAC: this.stateAdress,
        ST_CEP_SAC: this.cep,
        identificadorContrato: this.accName,
      };
      if (this.coupon.lenght > 0) payload.cupom = this.coupon;
      if (this.payment.value == "card") {
        payload.FL_PAGAMENTOPREF_SAC = 3;
        payload.ST_CARTAO_SAC = this.cardNumber;
        payload.ST_NOMECARTAO_SAC = this.cardName;
        payload.ST_MESVALIDADE_SAC = this.cardValidate.month;
        payload.ST_ANOVALIDADE_SAC = this.cardValidate.year;
        payload.ST_SEGURANCACARTAO_SAC = this.cardValidate.number;
        payload.ST_CARTAOBANDEIRA_SAC = this.getCardFlag(this.cardNumber);
      } else if (this.payment.value == "bankSlip")
        payload.FL_PAGAMENTOPREF_SAC = 0;

      this.$http({
        method: "POST",
        url: "/g/acc/plan/financial/checkout/",
        data: payload,
      })
        .then(() => {
          //this.updateOnboarding();
          //mensagem pagamento, boleto
          this.loading = false;
          this.$vs.notify({
            title: this.$t("Ok"),
            text: "Sua assinatura foi realizada",
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
            time: 4000,
          });
          window.location.replace("/dashboard");
        })
        .catch((err) => {
          let msg = "Por favor revise os seus dados";
          if (err.response.data.message) msg = err.response.data.message;
          this.loading = false;
          this.$vs.notify({
            time: 3500,
            title: this.$t("Error"),
            text: msg,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        });
    },
    getCardFlag(card1) {
      let card = card1.replace(/[^0-9]+/g, "");

      var cards = {
        visa: /^4[0-9]{12}(?:[0-9]{3})/,
        mastercard: /^5[1-5][0-9]{14}/,
        diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        amex: /^3[47][0-9]{13}/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
        elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        aura: /^(5078\d{2})(\d{2})(\d{11})$/,
      };

      for (var flag in cards) {
        if (cards[flag].test(card)) {
          return flag;
        }
      }

      return false;
    },
    setAdress(responseCep) {
      if (!("erro" in responseCep)) {
        this.showAdress = true;
        this.address = responseCep.logradouro;
        this.neighborhood = responseCep.bairro;
        this.city = responseCep.localidade;
        this.stateAdress = responseCep.uf;
      } else {
        //CEP não Encontrado.
        this.errorCep = "Cep não encontrado";
      }
    },
    searchCep() {
      let cep = this.cep.replace(/\D/g, "");
      var validacep = /^[0-9]{8}$/;
      //Valida o formato do CEP.
      if (validacep.test(cep)) {
        axios
          .get(`https://viacep.com.br/ws/${this.cep}/json/`)
          .then((response) => this.setAdress(response.data));
      } else {
        this.errorCep = "Formato de CEP inválido";
      }
    },
  },
  mounted() {
    this.getPlanDetails();
  },
};
</script>
<style>
.label-onboarding {
  font-size: 0.85rem;
  background-color: white;
  margin-left: 1rem;
  padding: 0 0.5rem 0 0.5rem;
  width: fit-content;
  position: relative;
  bottom: -10px;
  order: -1;
}
.input-onboarding {
  padding-left: 8px;
}
.input-onboarding:focus {
  border: solid 1px rgba(var(--vs-primary), 1);
}
.input-onboarding:focus + span + .label-onboarding,
.input-onboarding:focus + .label-onboarding,
.vue-tel-input:focus-within + span + .label-onboarding {
  color: rgba(var(--vs-primary), 1);
}
.spinner {
  border: 4px solid #ffffffc2;
  border-radius: 50%;
  border-top: 4px solid #e0e5e99c;
  border-bottom: 4px solid #e0e5e988;
  border-right: 4px solid #e0e5e95d;
  width: 32px;
  height: 32px;
  -webkit-animation: rotate-in-center-2 2s ease-in-out infinite both;
  animation: rotate-in-center-2 2s ease-in-out infinite both;
}
@-webkit-keyframes rotate-in-center-2 {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
@keyframes rotate-in-center-2 {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
</style>
